<template>
  <div>
    <v-card width="1000" class="mx-auto">
      <v-card-title>
        <span class="headline">
          <b>إضافة مهمة</b>
        </span>
      </v-card-title>
      <v-divider></v-divider>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 sm6>
                <v-text-field
                  label="اسم المهمة"
                  placeholder="اسم المهمة"
                  v-model="task.Name"
                  :rules="nameRules"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6>
                <v-select
                  v-model="selectedTaskType"
                  :items="TaskTypes"
                  item-text="item"
                  item-value="value"
                  label="نوع المهمة"
                  :rules="selectedTaskTypeRules"
                  @change="checkTask"
                  persistent-hint
                  return-object
                  require
                ></v-select>
              </v-flex>
              <v-flex v-if="visitTask" xs12 sm6>
                <v-select
                  v-model="externalsType"
                  :items="externalsTypes"
                  @change="getExternals"
                  item-text="item"
                  item-value="value"
                  label="نوع الجهة الخارجية"
                  :rules="externalsTypeRules"
                  persistent-hint
                  return-object
                  required
                ></v-select>
              </v-flex>
              <v-flex v-if="visitTask" xs12 sm6>
                <v-autocomplete
                  :items="externals"
                  v-model="external"
                  :filter="customFilter2"
                  color="white"
                  item-text="item"
                  label=" الجهة الخارجية"
                  :rules="externalsRules"
                  item-value="value"
                  return-object
                  required
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 sm6>
                <v-text-field
                  label="المستخدم المنشئ"
                  placeholder="المستخدم المنشئ"
                  v-model="user.fullName"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6>
                <v-autocomplete
                  :items="users"
                  v-model="ResponsibleId"
                  :filter="customFilter"
                  color="white"
                  item-text="item"
                  label="المستخدم الموكلة له"
                  :rules="usersRules"
                  item-value="value"
                  return-object
                  required
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 sm6>
                <template>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-dialog
                        ref="dialog"
                        v-model="modal"
                        :return-value.sync="date"
                        persistent
                        width="290px"
                         attach=""
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="task.StartDate"
                            label="تاريخ البداية"
                            prepend-icon="mdi-calendar"
                            :rules="StartDateRules"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="task.StartDate" scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="modal = false">
                            إغلاق
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(date)"
                          >
                            حفظ
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </template>
              </v-flex>
              <v-flex xs12 sm6>
                <template>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-dialog
                        ref="dialog2"
                        v-model="modal2"
                        :return-value.sync="date2"
                        persistent
                        width="290px"
                         attach=""
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="task.EndDate"
                            label="تاريخ النهاية"
                            prepend-icon="mdi-calendar"
                            :rules="EndDateRules"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="task.EndDate" scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="modal2 = false">
                            إغلاق
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog2.save(date2)"
                          >
                            حفظ
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </template>
              </v-flex>
              <v-flex xs12 sm12>
                <v-textarea
                  label="ملاحظات المهمة"
                  v-model="task.Notes"
                  rows="2"
                ></v-textarea>
              </v-flex>

              <v-flex xs12 sm12>
                <v-switch
                  v-model="repeat"
                  label="التكرار"
                  color="info"
                  :value="true"
                  hide-details
                ></v-switch>
                <v-spacer></v-spacer>
              </v-flex>
              <v-flex xs12 sm6 v-if="repeat">
                <v-select
                  v-model="selecyedTaskRepetition"
                  :items="TaskRepetition"
                  item-text="item"
                  item-value="value"
                  label="تكرار المهمة"
                  :rules="TaskRepetitionRules"
                  persistent-hint
                  return-object
                  required
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 v-if="repeat">
                <v-text-field
                  label="عدد مرات التكرار"
                  placeholder="عدد مرات التكرار"
                  v-model="task.Reapets"
                  required
                  type="number"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="addTask"
            style="background: #5867dd; color: white; margin-left: 5px"
            dark
          >
            <b>حفظ</b>
          </v-btn>
          <!--     <v-btn @click="$router.go(-1)" style="background:gray;" dark>
                    <b>رجوع</b>
                </v-btn>-->
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      external: "",
      externalsType: { item: "مصانع", value: 1 },
      externalsTypes: [
        { item: "اختر جهة خارجية", value: "0" },
        { item: "مشاريع", value: 2 },
        { item: "مقاولات", value: 3 },
        { item: "مباسط", value: 4 },
        { item: "مصانع", value: 1 },
        { item: "مواقف", value: 5 },
        { item: "ناقلات", value: 7 }
      ],
      externals: [{ item: "اختر جهة", value: "0" }],
      externalsTypeRules: [
        v => !!v || "نوع الجهة الخارجية مطلوبة",
        v => v.value != "0" || "الاختيار غير مقبول"
      ],
      externalsRules: [
        v => !!v || "الجهة الخارجية مطلوبة",
        v => v.value != "0" || "الاختيار غير مقبول"
      ],
      visitTask: false,
      valid: true,
      usersRules: [
        v => !!v || "المستخدم مطلوب",
        v => v.value != "0" || "الاختيار غير مقبول"
      ],
      StartDateRules: [v => !!v || "تاريخ البداية مطلوب"],
      EndDateRules: [
        v => !!v || "تاريخ النهاية مطلوب",
        v =>
          v >= this.task.StartDate ||
          "تاريخ النهاية لا يمكن أن يسبق  تاريخ البداية"
      ],
      TaskRepetitionRules: [v => v.value != "0" || "الاختيار غير مقبول"],
      selectedTaskTypeRules: [
        v => !!v || "نوع المهمة مطلوبة",
        v => v.value != "0" || "الاختيار غير مقبول"
      ],
      TaskStatusRules: [
        v => !!v || "حالة المهمة مطلوبة",
        v => v.value != "0" || "الاختيار غير مقبول"
      ],
      date: new Date().toISOString().substr(0, 10),
      date2: new Date().toISOString().substr(0, 10),
      modal: false,
      modal2: false,
      repeat: false,
      users: [{ item: "اختر المستخدم الموكلة له المهمة", value: "0" }],
      selectedTaskStatus: 0,
      TaskStatus: [
        { value: "0", item: "اختر حالة المهمة" },
        { value: "5", item: "بانتظار المراجعة" },
        { value: "2", item: "قيد التقدم" },
        { value: "3", item: "منجزة" },
        { value: "4", item: "موكلة" }
      ],
      nameRules: [
        v => !!v || "الاسم مطلوب",
        v => (v && v.length >= 3) || "الاسم لا يجب أن يكون أقل من ثلاث محارف"
      ],
      selectedTaskType: { value: "2", item: "تنبيه" },
      TaskTypes: [
        { value: "0", item: "اختر نوع المهمة" },
        { value: "1", item: "زيارة" },
        { value: "2", item: "تنبيه" },
        { value: "3", item: "طلب معلومات " }
      ],
      TaskRepetition: [
        { value: "0", item: "اختر تكرار المهمة" },
        { value: "d", item: "يومي" },
        { value: "w", item: "إسبوعي" },
        { value: "m", item: "شهري" },
        { value: "y", item: "سنوي" }
      ],
      selecyedTaskRepetition: 0,
      user: null,
      ResponsibleId: 0,
      role: "",
      task: {
        Name: "",
        TypeId: "",
        ResponsibleId: "",
        CreatorId: "",
        StatusId: "",
        Notes: "",
        StartDate: new Date().toISOString().substr(0, 10),
        EndDate: new Date().toISOString().substr(0, 10),
        ReapetType: "",
        Reapets: ""
      }
    };
  },
  methods: {
    async getExternals() {
      // this.externals=[
      //     { item: 'اختر جهة', value: '0' },
      //   ];
      if (this.externalsType.value == 2) {
        await this.ApiService.get("External/getallproject?id=2")
          .then(res => {
            var allExternals = res.data.responseData;

            allExternals.forEach(el => {
              if (this.externalsType.value == 5) {
                this.externals.push({ item: el.name, value: el.idproject });
              } else {
                this.externals.push({ item: el.name, value: el.externalId });
              }
            });
            this.factories = res.data.responseData;
          })
          .catch(() => {});
      } else if (this.externalsType.value == 7) {
        await this.ApiService.get("Transporters/getalltransporters")
          .then(res => {
            var allExternals = res.data.responseData;

            allExternals.forEach(el => {
              this.externals.push({ item: el.name, value: el.idtransporter });
            });
            this.factories = res.data.responseData;
          })
          .catch(() => {});
      } else {
        await this.ApiService.get(
          "external/getallexternals?id=" + this.externalsType.value
        )
          .then(res => {
            var allExternals = res.data.responseData;
            allExternals.forEach(el => {
              this.externals.push({ item: el.name, value: el.idexternal });
            });
            this.factories = res.data.responseData;
          })
          .catch(() => {});
      }
    },
    checkTask() {
      if (this.selectedTaskType.value == 1) {
        this.task.StatusId = 4;
        this.visitTask = true;
        this.getExternals();
      } else {
        this.visitTask = false;
      }
    },
    addTask() {
      if (this.$refs.form.validate()) {
        let visitIfo = null;
        if (this.selectedTaskType.value == 1) {
          if (this.externalsType.value == 7) {
            visitIfo = {
              Type: "ناقلات",
              TransporterId: this.external.value,
              ExternalId: ""
            };
          } else if (this.externalsType.value == 2) {
            visitIfo = {
              Type: "مشاريع",
              ExternalId: this.external.value
            };
          } else if (this.externalsType.value == 3) {
            visitIfo = {
              Type: "مقاولات",
              ExternalId: this.external.value
            };
          } else if (this.externalsType.value == 4) {
            visitIfo = {
              Type: "مباسط",
              ExternalId: this.external.value
            };
          } else if (this.externalsType.value == 5) {
            visitIfo = {
              Type: "مواقف",
              ExternalId: this.external.value
            };
          } else {
            visitIfo = {
              Type: "مصانع",
              ExternalId: this.external.value
            };
          }
        }

        let data = {
          task: {
            StatusId: 4,
            ResponsibleId: this.ResponsibleId.value,
            TypeId: this.selectedTaskType.value,
            CreatorId: this.user.id,
            StartDate: this.task.StartDate,
            EndDate: this.task.EndDate,
            Name: this.task.Name,
            Notes: this.task.Notes
          },
          visit: this.selectedTaskType.value == 1 ? visitIfo : ""
        };
        if (this.repeat == true) {
          data = {
            task: {
              Name: this.task.Name,
              TypeId: this.selectedTaskType.value,
              ResponsibleId: this.ResponsibleId.value,
              CreatorId: this.user.id,
              StatusId: 4,
              Notes: this.task.Notes,
              StartDate: this.task.StartDate,
              EndDate: this.task.EndDate,
              ReapetType: this.selecyedTaskRepetition.value,
              Reapets: this.task.Reapets
            },
            visit: {
              visit: this.selectedTaskType.value == 1 ? visitIfo : ""
            },
            productNeedVisit: [],
            photos: []
          };
        }
        this.ApiService.post("task/addtask", data)
          .then(res => {
            this.hel.showSnackMsg("تمت العملية بنجاح", "#58dd5b", 2000);
            let msg =
              "تم إسناد مهمة " +
              this.task.Name +
              " لك من قبل " +
              this.user.fullName;
           
            this.$socket
              .invoke(
                "SendNewNotification",
                this.ResponsibleId.value,
                this.user.id,
                msg,
                res.data.responseData.idtask
              )
              .then(() => {});
            this.$router.push({ name: "tasks" });
          })
          .catch(() => {
            this.hel.showSnackMsg("فشلت العملية", "#af1616", 2000);
          });
      }
    },
    customFilter(item, queryText) {
      const textOne = item.item.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    customFilter2(item, queryText) {
      const textOne = item.item.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    async getUsers() {
      this.users = [{ item: "اختر مستخدم", value: "0" }];
      await this.ApiService.get("auth/listusers")
        .then(res => {
          var allUsers = res.data;
          allUsers.forEach(el => {
            this.users.push({ value: el.user.id, item: el.user.fullName });
          });
        })
        .catch(() => {});
    }
  },
  created() {
    this.user = JSON.parse(window.localStorage.getItem("authUser"));
    this.role = window.localStorage.getItem("roles");
    this.ResponsibleId = { item: this.user.fullName, value: this.user.id };
    this.getUsers();
  }
};
</script> 

<style lang="scss"></style>